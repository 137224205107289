// eslint-disable-next-line import/no-internal-modules
import { zoneConfig } from '@rx-angular/cdk/zone-configurations';

/**
 *  Reduces overhead introduced by the Zone.js,
 *  by disabling patching for certain APIs.
 */

// Not needed for most of Angular apps
zoneConfig.global.disable.requestAnimationFrame();
zoneConfig.global.disable.canvas();
zoneConfig.global.disable.geolocation();
zoneConfig.global.disable.blocking();
zoneConfig.global.disable.MutationObserver();
zoneConfig.global.disable.IntersectionObserver();
zoneConfig.global.disable.FileReader();

// old syntax, not available via rx-angular:
// (window as any).__Zone_disable_Error = true;
// (window as any).__Zone_disable_toString = true;
// (window as any).__Zone_disable_PromiseRejectionEvent = true;
// (window as any).__Zone_disable_IE_check = true;
// (window as any).__Zone_disable_mediaQuery = true;
// (window as any).__Zone_disable_notification = true;
// (window as any).__Zone_disable_MessagePort = true;
// (window as any).__Zone_enable_cross_context_check = false;

zoneConfig.global.disable.fs();
zoneConfig.global.disable.node_timers();
zoneConfig.runtime.disable.ignoreConsoleErrorUncaughtError();

zoneConfig.global.disable.customElements();
zoneConfig.global.disable.registerElement();
zoneConfig.global.disable.defineProperty();
zoneConfig.global.disable.crypto();
zoneConfig.global.disable.nextTick();
zoneConfig.global.disable.EventEmitter();
zoneConfig.global.disable.DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION();

// Optional for some Angular apps
zoneConfig.global.disable.timers();

// Scroll events
zoneConfig.useUnpatchedPassiveScrollEvents();

// XHR
zoneConfig.global.disable.XHR();
zoneConfig.unpatchXHR();

const events = [
  'animationcancel',
  'animationend',
  'animationiteration',
  'animationstart',
  'copy',
  'cut',
  'dragend',
  'dragenter',
  'dragleave',
  'dragover',
  'dragstart',
  'drag',
  'drop',
  'fullscreenchange',
  'fullscreenerror',
  'gotpointercapture',
  'keydown',
  'keypress',
  'keyup',
  'lostpointercapture',
  'paste',
  'pointercancel',
  'pointerdown',
  'pointerenter',
  'pointerleave',
  'pointerlockchange',
  'pointerlockerror',
  'pointermove',
  'pointerout',
  'pointerover',
  'pointerup',
  'readystatechange',
  'scroll',
  'selectionchange',
  'selectstart',
  'touchcancel',
  'touchend',
  'touchmove',
  'touchstart',
  'transitioncancel',
  'transitionend',
  'transitionrun',
  'transitionstart',
  'visibilitychange',
  'wheel',
  'afterscriptexecute',
  'auxclick',
  'beforescriptexecute',
  'blur',
  'click',
  'compositionend',
  'compositionstart',
  'compositionupdate',
  'contextmenu',
  'dblclick',
  'error',
  'focusin',
  'focusout',
  'focus',
  'gesturechange',
  'gestureend',
  'gesturestart',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseout',
  'mouseover',
  'mouseup',
  'mousewheel',
  'msContentZoom',
  'overflow',
  'select',
  'show',
  'underflow',
  'webkitmouseforcechanged',
  'webkitmouseforcedown',
  'webkitmouseforceup',
  'webkitmouseforcewillbegin',
  'message',
  'open',
  'abort',
  'loadend',
  'loadstart',
  'load',
  'progress',
  'webglcontextcreationerror',
  'webglcontextlost',
  'webglcontextrestored',
  'toggle',
  'cancel',
  'close',
  'beforeinput',
  'change',
  'input',
  'formdata',
  'reset',
  'submit',
  'invalid',
  'search',
  'canplaythrough',
  'canplay',
  'durationchange',
  'emptied',
  'ended',
  'loadeddata',
  'loadedmetadata',
  'pause',
  'playing',
  'play',
  'ratechange',
  'seeked',
  'seeking',
  'stalled',
  'suspend',
  'timeupdate',
  'volumechange',
  'waiting',
  'slotchange',
  'cuechange',
  'enterpictureinpicture',
  'leavepictureinpicture',
  'versionchange',
  'blocked',
  'upgradeneeded',
  'success',
  'complete',
  'devicechange',
  'addtrack',
  'removetrack',
  'mute',
  'unmute',
  'messageerror',
  'merchantvalidation',
  'paymentmethodchange',
  'shippingaddresschange',
  'shippingoptionchange',
  'payerdetailchange',
  'resourcetimingbufferfull',
  'resize',
  'bufferedamountlow',
  'closing',
  'tonechange',
  'gatheringstatechange',
  'selectedcandidatepairchange',
  'statechange',
  'addstream',
  'connectionstatechange',
  'datachannel',
  'icecandidateerror',
  'icecandidate',
  'iceconnectionstatechange',
  'icegatheringstatechange',
  'negotiationneeded',
  'removestream',
  'signalingstatechange',
  'track',
  'audioprocess',
  'activate',
  'contentdelete',
  'install',
  'notificationclick',
  'pushsubscriptionchange',
  'push',
  'connect',
  'audioend',
  'audiostart',
  'end',
  'nomatch',
  'result',
  'soundend',
  'soundstart',
  'speechend',
  'speechstart',
  'start',
  'voiceschanged',
  'boundary',
  'mark',
  'resume',
  'beginEvent',
  'endEvent',
  'repeatEvent',
  'unload',
  'removeTrack',
  'afterprint',
  'appinstalled',
  'beforeprint',
  'beforeunload',
  'devicemotion',
  'deviceorientation',
  'gamepadconnected',
  'gamepaddisconnected',
  'hashchange',
  'languagechange',
  'offline',
  'online',
  'orientationchange',
  'pagehide',
  'pageshow',
  'popstate',
  'rejectionhandled',
  'storage',
  'unhandledrejection',
  'vrdisplayactivate',
  'vrdisplayblur',
  'vrdisplayconnect',
  'vrdisplaydeactivate',
  'vrdisplaydisconnect',
  'vrdisplayfocus',
  'vrdisplaypointerrestricted',
  'vrdisplaypointerunrestricted',
  'vrdisplaypresentchange',
  'timeout',
  'inputsourceschange',
  'selectend',
  'squeezeend',
  'squeezestart',
  'squeeze',
];

// (window as any).__Zone_ignore_on_properties = [
//   {
//     target: XMLHttpRequest.prototype,
//     ignoreProperties: ['load', 'readystatechange', 'loadstart', 'loadend', 'progress'],
//   },
// ];

zoneConfig.global.disable.on_property();

zoneConfig.events.disable.PASSIVE_EVENTS(events);
zoneConfig.events.disable.UNPATCHED_EVENTS(events);

// Needed for all Angular apps:
// zoneConfig.global.disable.ZoneAwarePromise();
// zoneConfig.global.disable.EventTarget();
zoneConfig.global.disable.EventTargetLegacy(); // yet we still set this flag
